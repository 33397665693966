@media screen and (min-width: 830px) {
    .InvalidPath {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 74vh;
        .invalid-message {
            padding: 20px;
            background-color: var(--convCol);
            border-radius: 10px;
        }
    }
}