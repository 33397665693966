body.light {
    /* General Colours */
    --mainCol: rgb(255, 255, 246);
    --textCol: rgb(18, 18, 18);
    --chatCol: rgba(255, 255, 255, 0.208);
    --convCol: rgb(255, 253, 239);
    --compShadowCol: rgba(0, 0, 0, 0.4);
    /* Button Colours */
    --btnColour: var(--convCol);
    --btnTextCol:  rgb(124, 126, 147);
    --btnShadowCol:  rgba(0, 0, 0, 0.2);
        /* hover */
        --btnColourHov: rgba(234, 226, 167, 0.522);
        --btnTextColHov:  rgba(0, 0, 0, 0.75);
        --btnShadowColHov:  rgba(242, 243, 173, 0.15);
    /* Select Language Colours */
    --selectCol: var(--convCol);
    --selectTextCol:  rgba(0, 0, 0, 0.4);
        /* hover */
        --selectColHov: beige;
        --selectTextColHov:  rgba(0, 0, 0, 0.7);
    /* Conversation Component Colours */
    --convTitleShadowCol: var(--compShadowCol);
    /* Message Colours */
    --userMsgCol: beige;
    --argMsgCol: white;
    --MsgShadowCol:  rgba(0, 0, 0, 0.1);
    --codeBlockShadowCol: var(--btnShadowCol);
    /* Scrollbar */
    --scrollBarCol: white;
    --scrollBarBorderCol: rgba(0, 0, 0, 0.15);
    --scrollTrackCol: rgba(0, 0, 0, 0.025);
    /* Help Pop Up */
    --helpPopUpOverlayCol: rgba(199, 199, 199, 0.45);
    --helpPopUpCol: white;
    --helpLinkCol: rgb(167, 167, 19);
    --helpLinkColHov: rgb(84, 84, 255);
    /* Error */
}