@media screen and (min-width: 830px) {
    body {
        background-color: var(--mainCol);
        color: var(--textCol);
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;

        .Home {
            margin: auto;
            text-align: center;
            width: 70%;
        }

        .ChatComponent {
            box-shadow: 0px 2px 5px var(--compShadowCol);
            background-color: var(--chatCol);
            padding: 10px;
            border-radius: 25px;
            select {
                border: 0;
                border-radius: 5px;
                padding: 3px;
                background-color: var(--selectCol);
                color: var(--selectTextCol);
                transition: 0.6s;
            }
            select:hover {
                background-color: var(--selectColHov);
                color: var(--selectTextColHov);
                transition: 0.3s;
            }
        }
        
        .LangSelect {
            padding: 10px;
        }

        .Conversation {
            height:55vh;
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            background-color: var(--convCol);
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 7px;
            overflow-y: scroll;
            padding: 10px;
            /* style messages */
            #argot-message {
                align-self: flex-start;
                text-align: left;
                background-color: var(--argMsgCol);
                box-shadow: 0px 1px 5px var(--btnShadowCol);
                color: var(--argotMsgTextCol);
                padding: 10px;
                border-radius: 10px;
                margin: 5px;
                max-width: 70%;
            }
            #user-message {
                align-self: flex-end;
                text-align: left;
                background-color: var(--userMsgCol);
                box-shadow: 0px 1px 5px var(--btnShadowCol);
                color: var(--userMsgTextCol);
                padding: 10px;
                border-radius: 10px;
                margin: 5px;
                max-width: 70%;
            }
            .code-block {
                box-shadow: 0px 1px 3px var(--codeBlockShadowCol);
                border-radius: 10px;
                padding: 10px;
                p {
                    padding-left: 8px;
                    margin: 0px;
                }
            }
        }
        
        .Conversation::-webkit-scrollbar {
            width: 12px;
        }

        .Conversation::-webkit-scrollbar-thumb {
            background-color: var(--scrollBarCol);
            border: 1px solid var(--scrollBarBorderCol);
            border-radius: 20px;
        }
        
        .Conversation::-webkit-scrollbar-track {
            background-color: var(--scrollTrackCol);
            border-radius: 50px;
        }
    }
}