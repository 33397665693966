@media screen and (min-width: 830px) {
    .NavBarCol {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10vh;
        img {
            max-height: 75%;
            overflow: hidden;
        }
    }

}
