body.dark {
        /* General Colours */
        --mainCol: #042121;
        --textCol: white;
        --chatCol: rgba(0, 0, 0, 0.266);
        --convCol: #183D3D;
        --compShadowCol: rgba(0, 0, 0, 0.4);
        /* Button Colours */
        --btnColour: var(--convCol);
        --btnTextCol:  #189e8a;
        --btnShadowCol:  rgba(0, 0, 0, 0.2);
            /* hover */
            --btnColourHov: rgba(0, 0, 0, 0.309);
            --btnTextColHov:  white;
            --btnShadowColHov:  #256e6e50;
        /* Select Language Colours */
        --selectCol: var(--convCol);
        --selectTextCol: #189e8a5c;
            /* hover */
            --selectColHov:#256e6e;
            --selectTextColHov:  white;
        /* Conversation Component Colours */
        --convTitleShadowCol: var(--compShadowCol);
        /* Message Colours */
        --userMsgCol: rgba(255, 255, 255, 0.9);
        --userMsgTextCol: var(--mainCol);
        --argMsgCol: var(--mainCol);
        --argotMsgTextCol: rgba(255, 255, 255, 0.853);
        --MsgShadowCol:  rgba(0, 0, 0, 0.1);
        --codeBlockShadowCol: var(--selectColHov);
        /* Scrollbar */
        --scrollBarCol: rgba(249, 249, 249, 0.823);
        --scrollBarBorderCol: rgba(0, 0, 0, 0.15);
        --scrollTrackCol: rgba(0, 0, 0, 0.163);
        /* Help Pop Up */
        --helpPopUpOverlayCol: rgba(0, 0, 0, 0.45);
        --helpPopUpCol: var(--convCol);
        --helpLinkCol: var(--btnTextCol);
        --helpLinkColHov: rgb(0, 206, 0);
        /* Error */
        
    }