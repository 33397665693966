.SpeechRecognitionComponent {
    background-color: var(--convCol);
    padding: 10px;
    padding-bottom: 0px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    .ConversationTitle {
        padding: 5px;
        box-shadow: 0px 1px 5px var(--convTitleShadowCol);
        border-radius: 20px;
    }
    p {
        margin: 0;
        padding: 5px;
        /* border-bottom: 1px solid rgba(227, 227, 227, 0.144); */
    }
}