/* Modal.css */
@media screen and (min-width: 830px) {
    .help-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--helpPopUpOverlayCol);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        height: 100vh;
        width: 100vw;
    }

    .help-content {
        background-color: var(--helpPopUpCol);
        color: var(--helpTextCol);
        padding: 20px;
        border-radius: 8px;
        width: 80%;
        max-width: 900px;
        max-height: 500px;
        position: relative;
        text-align: left;
        h3 {
            text-align: center;
        }
        p {
            margin-bottom: 15px;
        }
        strong {
            font-weight: 500;
            color: var(--helpLinkColHov);
        }
        .closing-help-guide-text {
            text-align: center;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .help-message {
            background-color: var(--helpPopUpCol);
            box-shadow: 0px 2px 4px var(--btnShadowCol);
            border: 1px solid var(--btnShadowCol);
            border-radius: 10px;
            padding: 20px;
            margin: 0px;
            overflow-y: scroll;
            max-height: 300px;
            a {
                text-decoration: none;
                color: var(--helpLinkCol);
                transition: 0.6s;
            }
            a:hover {
                color: var(--helpLinkColHov);
                transition: 0.6s;
            }
        }
    }

    .help-content p::-webkit-scrollbar {
        width: 12px;
    }

    .help-content p::-webkit-scrollbar-thumb {
        background-color: var(--scrollBarCol);
        border: 1px solid var(--scrollBarBorderCol);
        border-radius: 20px;
    }

    .help-content p::-webkit-scrollbar-track {
        background-color: var(--scrollTrackCol);
        border-radius: 50px;
    }
}