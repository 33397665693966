Button {
    border-radius: 10px;
    background-color: var(--btnCol);
    color: var(--btnTextCol);
    border: 0px;
    box-shadow: 0px 2px 5px var(--btnShadowCol);
    padding: 5px;
    margin: 5px;
    min-width: 150px;
    transition: 0.6s;
}

Button:hover {
    background-color: var(--btnColourHov);
    color: var(--btnTextColHov);
    box-shadow: 0px 2px 5px var(--btnShadowColHov);
    transition: 0.4s;
}